import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Home } from './Home';
import { MyBonds } from './MyBonds';
import { NewBonds } from './NewBonds';
import { TopMenu, Footer, ScrollToTop, CookieNotice, GoogleAnalytics, YandexMetrica, MyFees } from './modules';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { AboutCode } from './AboutCode';
import { BondScreener } from './BondScreener';
import { Feedback } from './Feedback';
import { FeedbackTest } from './FeedbackTest';
import { Privacy } from './Privacy';
import './i18n';


const App = () => (
   <Router>
      <ScrollToTop />
      <GoogleAnalytics />
      <YandexMetrica />
      <MyFees />
      <TopMenu />
      <div>
         <Route exact={true} path="/" component={Home} />
         <Route path='/myBonds/' component={MyBonds} />
         <Route path='/newBonds/' component={NewBonds} />
         <Route path='/aboutCode/' component={AboutCode} />
         <Route path='/bondScreener/' component={BondScreener} />
         <Route path='/feedback/' component={Feedback} />
         <Route path='/feedbackTest/' component={FeedbackTest} />
         <Route path='/privacyPolicy/' component={Privacy} />
      </div>
      <Footer />
      <CookieNotice />
   </Router>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes())
   hydrate(<App />, rootElement);
else
   render(<App />, rootElement);