import React, { useState, useCallback, useEffect } from 'react';
import cookie from 'react-cookies';
import { BondCardLink, Btooltip } from './modules';

const russian = 'ru';
const english = 'en';
const siteLanguage = english;

export let Functions = {

    isRus: function() {
        return siteLanguage === russian;
    },

    isEng: function() {
        return siteLanguage === english;
    },

    getCurrentLanguage: function() {
        return siteLanguage;
    },

    isEmptyObject: function(obj) {
        return Object.keys(obj).length === 0;
    },

    supportsLocalStorage: function() {
        try {
            const test = 'test';
            window.localStorage.setItem(test, test);
            window.localStorage.removeItem(test);
            return true;
        }
        catch (error) {
            return false;
        }
    },

    updateBond: async function(element) {
        // const bondExists = (element) => {
        //     const bonds = Functions.getBondsInfo();
        //     if (bonds[element.code])
        //        return true;
        //     else
        //        return false;
        // };
        // const restoreBondInfo = (element) => {
        //     const bonds = Functions.getBondsInfo();
        //     const bondInfo = bonds[element.code];
        //     return {...element, ...bondInfo};
        // };
        // const updateBondInfo = (bond) => {
        //     const bonds = Functions.getBondsInfo();
        //     const code = bond.code;
        //     bonds[code] = bond;
        //     Functions.setToLs(bonds, 'bondsInfo');
        // };

        // if (Functions.supportsLocalStorage() && bondExists(element))
        //     return restoreBondInfo(element);

        return Functions.fetchBondInfo(element.code).then(bond => {
            if (bond.currency !== 'SUR')
                throw new Error('USD bonds not supported');
           
            //updateBondInfo(bond);
            return { ...element, ...bond };
        });
    },

    composeRequestUrl: function(code) {
        let board;
        const su = /su/i;
        if (su.test(code))
            board = 'TQOB';
        else
            board = 'TQCB';
        return "https://iss.moex.com/iss/engines/stock/markets/bonds/boards/" + board + "/securities/" + code;
    },

    fetchBondInfo: async function(code) {
        const url = Functions.composeRequestUrl(code) + ".json";

        return fetch(url).then(response => response.json()).then(json => {
            const bonds = Functions.composeBondInfo(json);
            if (!bonds.length)
                throw new Error(code + ' not found in MOEX');

            return bonds[0];
        });
    },

    fetchDayCandles: async function(code) {
        const tillDate = new Date();
        const fromDate = new Date(tillDate.getTime());
        fromDate.setMonth(fromDate.getMonth() - 23);
        const fromDateString = Functions.composeMySqlDate(fromDate);
        const tillDateString = Functions.composeMySqlDate(tillDate);
        const url = Functions.composeRequestUrl(code) + "/candles.json?from=" + fromDateString + "&till=" + tillDateString + "&interval=24";
        return fetch(url).then(response => response.json()).then(json => {
            const candles = Functions.mapCandles(json);
            return candles;
        });
    },

    fetchMinuteCandles: async function(code, date) {
        const url = Functions.composeRequestUrl(code) + "/candles.json?from=" + date + "&till=" + date + "&interval=1";
        return fetch(url).then(response => response.json()).then(json => {
            const candles = Functions.mapCandles(json, true);
            return candles;
        });
    },

    composeMySqlDate: function(date) {
        return [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        ].join('-');
    },
  
    getFromLs: function(name) {
        if (Functions.supportsLocalStorage()) {
            try {
                let data = window.localStorage.getItem(name);
                if (data)
                    data = JSON.parse(data);
                return data;
            }
            catch (error) {
                if (Functions.supportsLocalStorage())
                    window.localStorage.removeItem(name);
                window.location.reload();
            }
        }
    },

    setToLs: function(data, name) {
        if (Functions.supportsLocalStorage()) {
            data = JSON.stringify(data);
            window.localStorage.setItem(name, data);
        }
    },

    getBondsInfo: function() {
        return this.getFromLs('bondsInfo') || {};
    },

    getMyBonds: function() {
        return this.getFromLs('myBonds') || [];
    },

    updateMyBonds: function(bonds) {
        return this.setToLs(bonds, 'myBonds');
    },

    getNewBonds: function() {
        return this.getFromLs('newBonds') || [];
    },

    updateNewBonds: function(bonds) {
        Functions.setToLs(bonds, 'newBonds');
    },

    getStorageBonds: function(storage) {
        return this.getFromLs(storage) || [];
    },

    updateStorageBonds: function(bonds, storage) {
        Functions.setToLs(bonds, storage);
    },

    calculate: function(inputData, priceType, realRatePeriod) {
        const bond = { ...inputData };
        if (!priceType)
            priceType = 'lastPrice';
        bond.priceType = priceType;
        switch (priceType) {
            case 'lastPrice':
                bond.relativePrice = bond.currentPercent;
                break;
            case 'bid':
                bond.relativePrice = bond.bid;
                break;
            case 'offer':
                bond.relativePrice = bond.offer;
                break;
            default:
                bond.relativePrice = bond.currentPercent;
                break;
        }
        const todayMilliseconds = getTodayMilliseconds();
        if (Functions.isDateValid(bond.repaymentDate))
            bond.daysTillRedemption = millisecondsToDays(getDateMilliseconds(bond.repaymentDate) - todayMilliseconds);
        else
            bond.repaymentDate = null;
        bond.finalDate = bond.repaymentDate;
        bond.putOffer = false;
        if (Functions.isDateValid(bond.buyBackDate)) {
            bond.finalDate = bond.buyBackDate;
            bond.putOffer = true;
        }
        else
            bond.buyBackDate = null;
        let finalDateMilliseconds;
        if (bond.finalDate) {
            finalDateMilliseconds = getDateMilliseconds(bond.finalDate);
            bond.daysTillRepayment = millisecondsToDays(finalDateMilliseconds - todayMilliseconds);
            bond.yearsTillRepayment = bond.daysTillRepayment / 365;
        }
        const isRedeemed = Functions.isRedeemed(bond);
        let couponDateMilliseconds;
        if (Functions.isDateValid(bond.couponDate)) {
            couponDateMilliseconds = getDateMilliseconds(bond.couponDate);
            bond.daysTillCoupon = millisecondsToDays(couponDateMilliseconds - todayMilliseconds);
        }
        if (bond.relativePrice) {
            bond.currentCost = bond.lotValue / 100 * bond.relativePrice;
            bond.baseDiff = bond.lotValue - bond.currentCost;
            bond.baseDiffPercent = bond.baseDiff / bond.lotValue * 100;
        }
        if (bond.rate) {
            bond.dayRevenue = bond.couponValue / bond.couponPeriod;
            if (Functions.isSet(bond.baseDiff) && bond.dayRevenue)
                bond.baseDiffInDays = Math.round(bond.baseDiff / bond.dayRevenue);
        }
        else
            bond.dayRevenue = 0;
        bond.nkdForAmount = bond.nkd * bond.amount;
        if (bond.couponValue)
            bond.nkdPercent = bond.nkd / bond.couponValue * 100;
        if (isRedeemed)
            bond.nkdPercent = 0;

        if (bond.purchasePercent) {
            bond.dayRevenueForAmount = bond.dayRevenue * bond.amount;
            bond.purchaseCost = bond.lotValue / 100 * bond.purchasePercent;
            bond.purchaseCostForAmount = bond.purchaseCost * bond.amount;
            bond.feeForPurchase = calculateFee(bond.purchaseCostForAmount);
            if (bond.dayRevenueForAmount)
                bond.feeForPurchaseInDays = getDiffInDays(-bond.feeForPurchase);
                // bond.feeForPurchaseInDays = Math.round(-bond.feeForPurchase / ndfl(bond.dayRevenueForAmount));
            const purchaseDateMilliseconds = getDateMilliseconds(bond.purchaseDate);

            const taxYear = 2021;
            const taxYearMilliseconds = getDateMilliseconds(taxYear + '-01-01');
            bond.taxYearSplit = false;
            if (bond.ndflFree && purchaseDateMilliseconds < taxYearMilliseconds) {
                bond.taxYearSplit = true;
                bond.daysOwnAfter = millisecondsToDays(todayMilliseconds - taxYearMilliseconds) + 1;
                if (bond.daysOwnAfter < 0)
                    bond.daysOwnAfter = 0;
                bond.allDaysTillEndAfter = millisecondsToDays(finalDateMilliseconds - taxYearMilliseconds) + 1;
            }
            bond.daysOwn = millisecondsToDays(todayMilliseconds - purchaseDateMilliseconds) + 1;
            if (bond.daysOwn < 0)
                bond.daysOwn = 0;
            if (Functions.isSet(bond.currentCost)) {
                bond.currentCostForAmount = bond.currentCost * bond.amount;
                bond.feeForSale = calculateFee(bond.currentCostForAmount);
                if (bond.dayRevenueForAmount)
                    bond.feeForSaleInDays = getDiffInDays(-bond.feeForSale);
                    // bond.feeForSaleInDays = Math.round(-bond.feeForSale / ndfl(bond.dayRevenueForAmount));
                bond.costDiff = bond.currentCostForAmount - bond.purchaseCostForAmount;
            }
            bond.baseCostDiff = bond.lotValue * bond.amount - bond.purchaseCostForAmount;
            bond.baseCostDiffInDays = getDiffInDays(bond.baseCostDiff);
            bond.onePercentChangeInDays = getDiffInDays(bond.lotValue * 0.01 * bond.amount);
            bond.zeroOnePercentChangeInDays = getDiffInDays(bond.lotValue * 0.001 * bond.amount);

            let daysSum;
            if (!isRedeemed) {
                daysSum = bond.daysOwn + bond.daysTillCoupon;
                bond.daysTillPurchaseInCouponPeriod = ((bond.couponPeriod - (daysSum - (bond.couponPeriod * Math.trunc(daysSum / bond.couponPeriod)))) % bond.couponPeriod + 1) % bond.couponPeriod;
                if (Functions.isSet(bond.userNkd))
                    bond.buyNkd = bond.userNkd;
                else
                    bond.buyNkd = parseFloat((bond.daysTillPurchaseInCouponPeriod * bond.dayRevenueForAmount).toFixed(2));
                bond.buyNkdInDays = getDiffInDays(bond.buyNkd);
                bond.purchaseCostWithNkd = bond.purchaseCostForAmount + bond.buyNkd;
                if (bond.couponValue) {
                    bond.couponPayment = bond.couponValue * bond.amount;
                    bond.buyNkdPercent = bond.buyNkd / bond.couponPayment * 100;
                }
                bond.nkdDelta = bond.nkdForAmount - bond.buyNkd;
                bond.nkdDeltaInDays = getDiffInDays(bond.nkdDelta);
                if (bond.buyNkd === 0) {
                    bond.sellPeriod = bond.couponPeriod;
                    bond.waitPeriod = 0;
                    bond.currentSellPeriod = bond.couponPeriod;
                }
                else {
                    bond.sellPeriod = bond.couponPeriod - bond.daysTillPurchaseInCouponPeriod;
                    bond.waitPeriod = bond.couponPeriod - bond.sellPeriod;
                    if (bond.nkdDeltaInDays >= 0)
                        bond.currentSellPeriod = bond.daysTillCoupon - 2;
                    else
                        bond.currentWaitPeriod = Math.abs(bond.nkdDeltaInDays);
                }
            }
            else {
                bond.buyNkd = 0;
                bond.buyNkdPercent = 0;
                bond.purchaseCostWithNkd = bond.purchaseCostForAmount;
            }
            bond.buyNkdWithDays = '';
            
            let allDaysTillEnd;
            const fullPurchaseCost = bond.purchaseCostWithNkd + bond.feeForPurchase;
            const fullPurchaseSaleCost = fullPurchaseCost + bond.feeForSale;
            if (Functions.isSet(finalDateMilliseconds) && bond.dayRevenueForAmount) {
                allDaysTillEnd = millisecondsToDays(finalDateMilliseconds - purchaseDateMilliseconds) + 1;
                bond.allCouponPaymentCount = Math.trunc((allDaysTillEnd - 2) / bond.couponPeriod) + 1;
                if (bond.taxYearSplit) {
                    bond.allCouponPaymentCountAfter = Math.trunc((bond.allDaysTillEndAfter - 2) / bond.couponPeriod) + 1;
                    bond.allCouponPaymentCountBefore = bond.allCouponPaymentCount - bond.allCouponPaymentCountAfter;
                    bond.allCouponsBefore = bond.allCouponPaymentCountBefore * bond.couponPayment;
                    bond.allCouponsAfter = ndfl(bond.allCouponPaymentCountAfter * bond.couponPayment);
                    bond.allCouponsBeforeInDays = getDiffInDays(bond.allCouponsBefore);
                    bond.allCouponsAfterInDays = getDiffInDays(bond.allCouponsAfter, true);
                    bond.allCoupons = bond.allCouponsBefore + bond.allCouponsAfter;
                    bond.allCouponsInDays = bond.allCouponsBeforeInDays + bond.allCouponsAfterInDays;
                }
                else {
                    bond.allCoupons = ndfl(bond.allCouponPaymentCount * bond.couponPayment);
                    bond.allCouponsInDays = getDiffInDays(bond.allCoupons, true);
                }
                bond.repaymentNob = bond.lotValue * bond.amount - fullPurchaseCost;
                bond.repaymentNobInDays = getDiffInDays(bond.repaymentNob);
                bond.guaranteedTradeRevenue = ndfl(bond.repaymentNob);
                if (bond.dayRevenueForAmount) {
                    if (bond.taxYearSplit && bond.allCouponPaymentCountBefore)
                        bond.guaranteedTradeRevenueInDays = getDiffInDaysNdfl(bond.guaranteedTradeRevenue, true);
                    else
                        bond.guaranteedTradeRevenueInDays = getDiffInDays(bond.guaranteedTradeRevenue, true);
                }
                // bond.guaranteedRevenueByRepayment = ndfl(allDaysTillEnd * bond.dayRevenueForAmount) + ndfl(bond.lotValue * bond.amount - bond.purchaseCostForAmount - bond.feeForPurchase);
                bond.guaranteedRevenueByRepayment = bond.guaranteedTradeRevenue + bond.allCoupons;
                bond.guaranteedRevenueByRepaymentInDays = bond.guaranteedTradeRevenueInDays + bond.allCouponsInDays;
                // bond.guaranteedRevenueByRepaymentPercent = bond.guaranteedRevenueByRepayment / bond.purchaseCostForAmount * 100;
                bond.guaranteedRevenueByRepaymentPercent = bond.guaranteedRevenueByRepayment / fullPurchaseCost * 100;
                // bond.revenueByRepaymentPercent = bond.guaranteedRevenueByRepayment / bond.purchaseCostForAmount / allDaysTillEnd * 365 * 100;
                bond.revenueByRepaymentPercent = bond.guaranteedRevenueByRepaymentPercent / allDaysTillEnd * 365;
            }

            if (Functions.isSet(bond.currentCost) && bond.dayRevenue)
                bond.purchaseDiffInDays = getDiffInDays(bond.costDiff);
                // bond.purchaseDiffInDays = Math.round((bond.currentCost - bond.purchaseCost) / bond.dayRevenue);
            // if (Functions.isSet(bond.daysOwn)) {
            //     bond.couponEarnings = ndfl(bond.dayRevenueForAmount * bond.daysOwn); // теперь не имеет смысла
            //     bond.couponEarningsInDays = getDiffInDays(bond.couponEarnings, true);
            //     bond.couponEarningsDeltaDays = bond.couponEarningsInDays - bond.daysOwn;
            // }

            if (!isRedeemed) {
                bond.couponPaymentCount = Math.trunc((daysSum - 2) / bond.couponPeriod);
                if (bond.taxYearSplit) {
                    let daysSumAfter = bond.daysOwnAfter + bond.daysTillCoupon - 2;
                    if (daysSumAfter < 0)
                        daysSumAfter = 0;
                    bond.couponPaymentCountAfter = Math.trunc(daysSumAfter / bond.couponPeriod);
                    bond.couponPaymentCountBefore = bond.couponPaymentCount - bond.couponPaymentCountAfter;
                    bond.couponsBefore = bond.couponPaymentCountBefore * bond.couponPayment;
                    bond.couponsAfter = ndfl(bond.couponPaymentCountAfter * bond.couponPayment);
                    bond.couponsBeforeInDays = getDiffInDays(bond.couponsBefore);
                    bond.couponsAfterInDays = getDiffInDays(bond.couponsAfter, true);
                    bond.coupons = bond.couponsBefore + bond.couponsAfter;
                    bond.couponsInDays = bond.couponsBeforeInDays + bond.couponsAfterInDays;
                }
                else {
                    bond.coupons = ndfl(bond.couponPaymentCount * bond.couponPayment);
                    bond.couponsInDays = getDiffInDays(bond.coupons, true);
                }
            }

            if (Functions.isSet(bond.costDiff)) {
                // bond.currentRevenue = ndfl(bond.costDiff - bond.feeForPurchase - bond.feeForSale) + bond.couponEarnings;
                bond.nob = bond.costDiff + bond.nkdForAmount - bond.buyNkd - bond.feeForPurchase - bond.feeForSale;
                const nobForRealRate = bond.nkdForAmount - bond.buyNkd - bond.feeForPurchase * 2;
                bond.tradeRevenue = ndfl(bond.nob);
                let currentRevenueForRealRate = ndfl(nobForRealRate);
                if (bond.dayRevenueForAmount) {
                    bond.nobInDays = getDiffInDays(bond.nob);
                    if (bond.taxYearSplit && bond.couponPaymentCountBefore)
                        bond.tradeRevenueInDays = getDiffInDaysNdfl(bond.tradeRevenue, true);
                    else
                        bond.tradeRevenueInDays = getDiffInDays(bond.tradeRevenue, true);
                }
                bond.currentRevenue = bond.tradeRevenue;
                if (Functions.isSet(bond.coupons)) {
                    bond.currentRevenue += bond.coupons;
                    currentRevenueForRealRate += bond.coupons;
                }
                if (bond.dayRevenueForAmount) {
                    bond.calculatedDaysOwn = bond.tradeRevenueInDays + bond.couponsInDays;
                    bond.calculatedDaysOwnDiff = bond.calculatedDaysOwn - bond.daysOwn;
                }
                // bond.currentRevenuePercent = bond.currentRevenue / bond.purchaseCostForAmount * 100;
                bond.currentRevenuePercent = bond.currentRevenue / fullPurchaseSaleCost * 100;
                // bond.currentYearRevenuePercent = bond.currentRevenue / bond.purchaseCostForAmount / bond.daysOwn * 365 * 100;
                if (bond.daysOwn > 0) {
                    bond.currentYearRevenuePercent = bond.currentRevenuePercent / bond.daysOwn * 365;
                    bond.realRate = currentRevenueForRealRate / (bond.purchaseCostWithNkd + bond.feeForPurchase * 2) * 100 / bond.daysOwn * 365;
                }
                if (Functions.isSet(finalDateMilliseconds) && Functions.isSet(bond.guaranteedRevenueByRepayment)) {
                    bond.guaranteedRevenueDiff = bond.guaranteedRevenueByRepayment - bond.currentRevenue;
                    bond.remainRepaymentRate = bond.guaranteedRevenueDiff / bond.daysTillRepayment * 365 / fullPurchaseCost * 100;
                    bond.guaranteedRevenueDiffPercent = bond.guaranteedRevenueDiff / fullPurchaseSaleCost * 100;
                    if (bond.dayRevenueForAmount)
                        bond.guaranteedRevenueDiffInDays = getDiffInDays(bond.guaranteedRevenueDiff, true);
                    if (bond.guaranteedRevenueDiff < 0) {
                        bond.sellNow = true;
                        bond.sellNowValue = '';
                    }
                }
            }
            if (Functions.isSet(bond.baseDiff))
                bond.baseDiffForAmount = bond.baseDiff * bond.amount;

            if (bond.dayRevenueForAmount)
                bond.dayRevenueForAmount = ndfl(bond.dayRevenueForAmount);
            if (bond.couponPayment) {
                if (bond.taxYearSplit) {
                    bond.couponPaymentBefore = bond.couponPayment;
                    bond.couponPaymentAfter = ndfl(bond.couponPayment);
                }
                bond.couponPayment = ndfl(bond.couponPayment);
            }

            convertEmptyToNegInfinity([
                'dayRevenueForAmount',
                'currentCostForAmount',
                'baseDiffForAmount',
                'feeForSale',
                'costDiff',
                'couponEarnings',
                'currentRevenue',
                'currentRevenuePercent',
                'currentYearRevenuePercent',
                'purchaseDiffInDays',
                'guaranteedRevenueByRepayment',
                'guaranteedRevenueByRepaymentPercent',
                'guaranteedRevenueDiff',
                'guaranteedRevenueDiffInDays',
                'revenueByRepaymentPercent',
                'couponPayment',
                'currentSellPeriod',
                'currentWaitPeriod'
            ]);
        }
        else {
            if (bond.currentCost && bond.daysTillRepayment && bond.dayRevenue) {
                const fee = calculateFee(bond.currentCost);
                const days = bond.daysTillRepayment - 1;
                const coupons = ndfl(days * bond.dayRevenue);
                bond.revenueByRepaymentPercent = (ndfl(bond.lotValue - bond.currentCost - fee) + coupons) / (bond.currentCost + bond.nkd + fee) * 100 / days * 365;

                if (Functions.isSet(realRatePeriod))
                    realRatePeriod = parseInt(realRatePeriod);
                else
                    realRatePeriod = 0; 
                let daysPeriod = realRatePeriod * 30;
                let coeff = 1;
                if (!daysPeriod || daysPeriod > bond.daysTillRepayment - 1) {
                    daysPeriod = bond.daysTillRepayment - 1;
                    coeff = 2;
                }
                bond.realRate = (-fee * coeff + ndfl(daysPeriod * bond.dayRevenue)) / (bond.currentCost + bond.nkd + fee * coeff) * 100 / daysPeriod * 365;
                bond.rateToBaseDiffInDays = bond.realRate / (1000 - bond.baseDiffInDays) * 1000;
            }

            if (bond.nkdPercent === 0) {
                bond.sellPeriod = bond.couponPeriod;
                bond.waitPeriod = 0;
            }
            else {
                bond.sellPeriod = bond.daysTillCoupon - 1;
                bond.waitPeriod = bond.couponPeriod - bond.sellPeriod;
            }
                
            //average - временно скрыл
            // data.averageCost = bond.lotValue / 100 * data.averagePercent;
            // data.averageCostForAmount = data.averageCost * data.amount;
            // data.risk = data.currentCost - data.averageCost;
            // data.riskPercent = data.risk / data.averageCost * 100;
            // data.riskForAmount = data.risk * data.amount;
            // data.averageDiffInDays = Math.round((data.averageCost - data.currentCost) / data.dayRevenue);
        }
        bond.nkdPeriod = '';
        bond.lowHighPrice = '';
        if (bond.totalBid && bond.totalOffer)
            bond.bidOfferRatio = bond.totalBid / bond.totalOffer;

        convertEmptyToNegInfinity([
            'currentPercent',
            'revenueByRepaymentPercent',
            'baseDiffInDays',
            'daysTillCoupon',
            'currentCost',
            'baseDiff',
            'baseDiffPercent',
            'dayRevenue',
            'daysTillRedemption',
            'daysTillRepayment',
            'bidOfferRatio',
            'nkdPercent',
            'rateToBaseDiffInDays'
        ]);
        

        // --- Вроде как не используется ---
        // data.potentialRevenueByRepayment = data.dayRevenue * data.daysTillRepayment;
        // data.potentialRevenueByRepaymentForAmount = data.potentialRevenueByRepayment * data.amount;
     
        // data.guaranteedRevenueByRepayment = data.potentialRevenueByRepayment - data.baseDiff;
        // data.guaranteedRevenueByRepaymentForAmount = data.guaranteedRevenueByRepayment * data.amount;
  
        function millisecondsToDays(milliseconds) {
           return Math.round(milliseconds / 1000 / 60 / 60 / 24);
        }

        function getTodayMilliseconds() {
            const now = new Date();
            return Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
        }

        function getDateMilliseconds(dateString) {
            return Date.parse(dateString);
        }
  
        function calculateFee(value) {
            const fees = Functions.getFees();
            if (fees) {
                let { fee, minFee } = fees;
                fee = parseFloat(fee) / 100;
                let result = value * fee;
                minFee = parseFloat(minFee);
                if (result < minFee)
                    result = minFee;
                return result;
            }
            else
                return 0;
        }

        function ndfl(value) {
            if (value > 0) {
                let coef;
                if (Functions.isRus())
                    coef = 0.87;
                else
                    coef = 0.7;
                return value * coef;
            }
            else
                return value;
        }

        function convertEmptyToNegInfinity(fields) {
            fields.forEach(field => {
                if (!Functions.isSet(bond[field]))
                    bond[field] = Number.NEGATIVE_INFINITY;
            });
        }

        function getDiffInDays(value, withNdfl) {
            if (!withNdfl)
                withNdfl = false;
            return Math.round(value / (withNdfl ? ndfl(bond.dayRevenueForAmount) : bond.dayRevenueForAmount));
        }

        function getDiffInDaysNdfl(value, withNdfl) {
            if (!withNdfl)
                withNdfl = false;
            return Math.round(value / (withNdfl && value > 0 ? ndfl(bond.dayRevenueForAmount) : bond.dayRevenueForAmount));
        }

        return bond;
    },

    mapColumnsToValues: function(json, name) {
        return json[name].data.map(bondInfo => {
            let result = {};
            const columns = json[name].columns;
            columns.forEach((value, index) => result[value] = bondInfo[index]);
            return result;
        });
    },

    composeBondInfo: function(json) {
        const bonds = Functions.mapColumnsToValues(json, 'securities');
        const markets = Functions.mapColumnsToValues(json, 'marketdata');
        return bonds.map((bond, index) => {
            return Functions.filterMoexData({
                bond: bond,
                market: markets[index]
            });
        });
    },

    mapCandles: function(json, intraday) {
        const candles = Functions.mapColumnsToValues(json, 'candles');
        return {
            candles: candles.map(candle => {
                return Functions.filterMoexCandles(candle, intraday);
            }),
            volume: candles.map(candle => {
                return Functions.filterMoexVolume(candle, intraday);
            })
        };
    },

    getUnixTimestamp: function(date) {
        date += " UTC";
        return new Date(date).getTime() / 1000;
    },

    filterMoexCandles: function(moex, intraday) {
        const series = {};
        if (intraday)
            series.time = Functions.getUnixTimestamp(moex.begin);
        else
            series.time = moex.begin.split(' ')[0];
        series.open = moex.open;
        series.close = moex.close;
        series.high = moex.high;
        series.low = moex.low;
        return series;
    },

    filterMoexVolume: function(moex, intraday) {
        const series = {};
        if (intraday)
            series.time = Functions.getUnixTimestamp(moex.begin);
        else
            series.time = moex.begin.split(' ')[0];
        series.value = moex.volume;
        return series;
    },

    filterMoexData: function(moex) {
        const parse = (value, func) => Functions.isSet(value) ? func(value) : value;
        const toInt = (value) => parse(value, parseInt);
        const toFloat = (value) => parse(value, parseFloat);

        let bond = {};
        bond.code = moex.bond.SECID;
        bond.board = moex.bond.BOARDID;
        bond.currentPercent = toFloat(moex.market.LAST) || toFloat(moex.bond.PREVPRICE);
        bond.name = moex.bond.SHORTNAME;
        bond.fullName = moex.bond.SECNAME;
        bond.engName = moex.bond.LATNAME;
        bond.rate = toFloat(moex.bond.COUPONPERCENT);
        bond.repaymentDate = moex.bond.MATDATE;
        bond.buyBackDate = moex.bond.BUYBACKDATE;
        bond.buyBackPrice = toFloat(moex.bond.BUYBACKPRICE);
        bond.couponDate = moex.bond.NEXTCOUPON;
        bond.couponPeriod = toInt(moex.bond.COUPONPERIOD);
        bond.isin = moex.bond.ISIN;
        bond.nkd = toFloat(moex.bond.ACCRUEDINT);
        bond.couponValue = toFloat(moex.bond.COUPONVALUE);
        bond.bid = toFloat(moex.market.BID);
        bond.offer = toFloat(moex.market.OFFER);
        bond.sector = toInt(moex.bond.SECTYPE);
        bond.listing = toInt(moex.bond.LISTLEVEL);
        bond.totalBid = toInt(moex.market.BIDDEPTHT);
        bond.totalOffer = toInt(moex.market.OFFERDEPTHT);
        bond.priceChange = toFloat(moex.market.LASTCHANGEPRCNT);
        bond.prevDayPriceChange = toFloat(moex.market.LASTCNGTOLASTWAPRICE);
        bond.tradeCount = toInt(moex.market.NUMTRADES);
        bond.totalTradeVolume = toInt(moex.market.VOLTODAY);
        bond.lastTradeVolume = toInt(moex.market.QTY);
        if (bond.tradeCount > 0)
            bond.lastTradeTime = moex.market.TIME;
        else
            bond.lastTradeTime = null;
        bond.openPrice = toFloat(moex.market.OPEN);
        bond.lowPrice = toFloat(moex.market.LOW);
        bond.highPrice = toFloat(moex.market.HIGH);
        bond.spread = toFloat(moex.market.SPREAD);
        bond.lotValue = toFloat(moex.bond.LOTVALUE);
        bond.averagePrice = toFloat(moex.market.WAPRICE);
        bond.prevDayAveragePriceChange = toFloat(moex.market.WAPTOPREVWAPRICE);
        bond.currency = moex.bond.FACEUNIT;
        return bond;
    },

    deleteRedeemedBonds: function(bond, bonds) {
        const repaymentDateSeconds = new Date(bond.repaymentDate).getTime();
        return bonds.filter(currentBond => {
            if (bond.code === currentBond.code && Functions.isSet(repaymentDateSeconds) && Date.now() > repaymentDateSeconds)
                return false;
            else
                return true;
        });
    },

    fillBonds: async function(storage, dispatch) {
        let bonds = Functions.getStorageBonds(storage);
        const clearOnError = (error, bond) => {
            const pattern = new RegExp(bond.code);
            if (pattern.test(error.message)) {
                if (Functions.isSet(bond.repaymentDate)) {
                    const newBonds = Functions.deleteRedeemedBonds(bond, bonds);
                    Functions.updateStorageBonds(newBonds, storage);
                }
            }
        };
        const clearUndefined = (bonds) => bonds.filter(bond => Functions.isSet(bond));
        
        if (bonds && bonds.length) {
            const promises = bonds.map(bond => Functions.updateBond(bond).catch(error => clearOnError(error, bond)));
            return Promise.all(promises).then((values) => {
                values = clearUndefined(values);
                // console.log(values);
                dispatch({ type: 'saveAllBonds', allBonds: values });
                return values;
            });
        }
        else
            return [];
    },

    sortItems: function(items, sorting) {
        return items.sort(Functions[sorting].bind(Functions));
    },

    sort: function(a, b, value, direction) {
        let x, y;
        switch (direction) {
           case 'asc':
              x = a;
              y = b;
              break;
           case 'desc':
              x = b;
              y = a;
              break;
           default:
              break;
        }
        if (x[value] < y[value])
            return -1;
        if (x[value] > y[value])
            return 1;
        return 0;
    },
  
    sortByRateDesc: function(a, b) {
        return this.sort(a, b, 'rate', 'desc');
    },
  
    sortByRateAsc: function(a, b) {
        return this.sort(a, b, 'rate', 'asc');
    },

    sortByRevenueDesc: function(a, b) {
        return this.sort(a, b, 'revenueByRepaymentPercent', 'desc');
    },
  
    sortByRevenueAsc: function(a, b) {
        return this.sort(a, b, 'revenueByRepaymentPercent', 'asc');
    },
  
    sortByPriceDesc: function(a, b) {
        return this.sort(a, b, 'currentPercent', 'desc');
    },
     
    sortByPriceAsc: function(a, b) {
        return this.sort(a, b, 'currentPercent', 'asc');
    },
    
    sortByBaseDiffInDaysDesc: function(a, b) {
        return this.sort(a, b, 'baseDiffInDays', 'desc');
    },
    
    sortByBaseDiffInDaysAsc: function(a, b) {
        return this.sort(a, b, 'baseDiffInDays', 'asc');
    },
    
    sortByDaysTillRepaymentDesc: function(a, b) {
        return this.sort(a, b, 'daysTillRepayment', 'desc');
    },
    
    sortByDaysTillRepaymentAsc: function(a, b) {
        return this.sort(a, b, 'daysTillRepayment', 'asc');
    },

    sortDaysTillCouponDesc: function(a, b) {
        return this.sort(a, b, 'daysTillCoupon', 'desc');
    },

    sortDaysTillCouponAsc: function(a, b) {
        return this.sort(a, b, 'daysTillCoupon', 'asc');
    },

    sortByCurrentYearRevenueDesc: function(a, b) {
        return this.sort(a, b, 'currentYearRevenuePercent', 'desc');
    },

    sortByCurrentYearRevenueAsc: function(a, b) {
        return this.sort(a, b, 'currentYearRevenuePercent', 'asc');
    },

    sortByCurrentRevenueDesc: function(a, b) {
        return this.sort(a, b, 'currentRevenuePercent', 'desc');
    },

    sortByCurrentRevenueAsc: function(a, b) {
        return this.sort(a, b, 'currentRevenuePercent', 'asc');
    },

    sortByPurchasePercentDesc: function(a, b) {
        return this.sort(a, b, 'purchasePercent', 'desc');
    },

    sortByPurchasePercentAsc: function(a, b) {
        return this.sort(a, b, 'purchasePercent', 'asc');
    },

    sortByAmountDesc: function(a, b) {
        return this.sort(a, b, 'amount', 'desc');
    },

    sortByAmountAsc: function(a, b) {
        return this.sort(a, b, 'amount', 'asc');
    },
    
    sortByBidDesc: function(a, b) {
        return this.sort(a, b, 'bid', 'desc');
    },

    sortByBidAsc: function(a, b) {
        return this.sort(a, b, 'bid', 'asc');
    },

    sortByOfferDesc: function(a, b) {
        return this.sort(a, b, 'offer', 'desc');
    },

    sortByOfferAsc: function(a, b) {
        return this.sort(a, b, 'offer', 'asc');
    },

    sortByTotalBidDesc: function(a, b) {
        return this.sort(a, b, 'totalBid', 'desc');
    },

    sortByTotalBidAsc: function(a, b) {
        return this.sort(a, b, 'totalBid', 'asc');
    },

    sortByTotalOfferDesc: function(a, b) {
        return this.sort(a, b, 'totalOffer', 'desc');
    },

    sortByTotalOfferAsc: function(a, b) {
        return this.sort(a, b, 'totalOffer', 'asc');
    },

    sortByBidOfferRatioDesc: function(a, b) {
        return this.sort(a, b, 'bidOfferRatio', 'desc');
    },

    sortByBidOfferRatioAsc: function(a, b) {
        return this.sort(a, b, 'bidOfferRatio', 'asc');
    },

    sortByPriceChangeDesc: function(a, b) {
        return this.sort(a, b, 'priceChange', 'desc');
    },

    sortByPriceChangeAsc: function(a, b) {
        return this.sort(a, b, 'priceChange', 'asc');
    },

    sortByPrevDayPriceChangeDesc: function(a, b) {
        return this.sort(a, b, 'prevDayPriceChange', 'desc');
    },

    sortByPrevDayPriceChangeAsc: function(a, b) {
        return this.sort(a, b, 'prevDayPriceChange', 'asc');
    },

    sortByTradeCountDesc: function(a, b) {
        return this.sort(a, b, 'tradeCount', 'desc');
    },

    sortByTradeCountAsc: function(a, b) {
        return this.sort(a, b, 'tradeCount', 'asc');
    },

    sortByTotalTradeVolumeDesc: function(a, b) {
        return this.sort(a, b, 'totalTradeVolume', 'desc');
    },

    sortByTotalTradeVolumeAsc: function(a, b) {
        return this.sort(a, b, 'totalTradeVolume', 'asc');
    },

    sortByLastTradeVolumeDesc: function(a, b) {
        return this.sort(a, b, 'lastTradeVolume', 'desc');
    },

    sortByLastTradeVolumeAsc: function(a, b) {
        return this.sort(a, b, 'lastTradeVolume', 'asc');
    },

    sortByOpenPriceDesc: function(a, b) {
        return this.sort(a, b, 'openPrice', 'desc');
    },

    sortByOpenPriceAsc: function(a, b) {
        return this.sort(a, b, 'openPrice', 'asc');
    },

    sortByLowPriceDesc: function(a, b) {
        return this.sort(a, b, 'lowPrice', 'desc');
    },

    sortByLowPriceAsc: function(a, b) {
        return this.sort(a, b, 'lowPrice', 'asc');
    },

    sortByHighPriceDesc: function(a, b) {
        return this.sort(a, b, 'highPrice', 'desc');
    },

    sortByHighPriceAsc: function(a, b) {
        return this.sort(a, b, 'highPrice', 'asc');
    },

    sortBySpreadDesc: function(a, b) {
        return this.sort(a, b, 'spread', 'desc');
    },

    sortBySpreadAsc: function(a, b) {
        return this.sort(a, b, 'spread', 'asc');
    },

    sortByLotValueDesc: function(a, b) {
        return this.sort(a, b, 'lotValue', 'desc');
    },

    sortByLotValueAsc: function(a, b) {
        return this.sort(a, b, 'lotValue', 'asc');
    },

    sortByAveragePriceDesc: function(a, b) {
        return this.sort(a, b, 'averagePrice', 'desc');
    },

    sortByAveragePriceAsc: function(a, b) {
        return this.sort(a, b, 'averagePrice', 'asc');
    },

    sortByPrevDayAveragePriceChangeDesc: function(a, b) {
        return this.sort(a, b, 'prevDayAveragePriceChange', 'desc');
    },

    sortByPrevDayAveragePriceChangeAsc: function(a, b) {
        return this.sort(a, b, 'prevDayAveragePriceChange', 'asc');
    },

    sortByNkdPercentDesc: function(a, b) {
        return this.sort(a, b, 'nkdPercent', 'desc');
    },

    sortByNkdPercentAsc: function(a, b) {
        return this.sort(a, b, 'nkdPercent', 'asc');
    },

    sortByCalculatedDaysOwnDiffDesc: function(a, b) {
        return this.sort(a, b, 'calculatedDaysOwnDiff', 'desc');
    },

    sortByCalculatedDaysOwnDiffAsc: function(a, b) {
        return this.sort(a, b, 'calculatedDaysOwnDiff', 'asc');
    },

    sortByRateToBaseDiffInDaysDesc: function(a, b) {
        return this.sort(a, b, 'rateToBaseDiffInDays', 'desc');
    },

    sortByRateToBaseDiffInDaysAsc: function(a, b) {
        return this.sort(a, b, 'rateToBaseDiffInDays', 'asc');
    },

    sortByCurrentSellPeriodDesc: function(a, b) {
        return this.sort(a, b, 'currentSellPeriod', 'desc');
    },

    sortByCurrentSellPeriodAsc: function(a, b) {
        return this.sort(a, b, 'currentSellPeriod', 'asc');
    },

    sortByCurrentWaitPeriodDesc: function(a, b) {
        return this.sort(a, b, 'currentWaitPeriod', 'desc');
    },

    sortByCurrentWaitPeriodAsc: function(a, b) {
        return this.sort(a, b, 'currentWaitPeriod', 'asc');
    },

    sortByBuyNkdPercentDesc: function(a, b) {
        return this.sort(a, b, 'buyNkdPercent', 'desc');
    },

    sortByBuyNkdPercentAsc: function(a, b) {
        return this.sort(a, b, 'buyNkdPercent', 'asc');
    },

    sortByRealRateDesc: function(a, b) {
        return this.sort(a, b, 'realRate', 'desc');
    },

    sortByRealRateAsc: function(a, b) {
        return this.sort(a, b, 'realRate', 'asc');
    },

    sortByRemainRepaymentRateDesc: function(a, b) {
        return this.sort(a, b, 'remainRepaymentRate', 'desc');
    },

    sortByRemainRepaymentRateAsc: function(a, b) {
        return this.sort(a, b, 'remainRepaymentRate', 'asc');
    },

    useRef: (callback, dep) => {
        const [element, setElement] = useState();
        const ref = useCallback(node => {
            if (node) {
                setElement(node);
                if (callback)
                    callback(node);
            }
        }, dep);
        return [ref, element];
    },

    confirmDelete: (t) => window.confirm(t('Alerts.delete')),

    isDateValid: (dateString) => Functions.isSet(dateString) && dateString !== '0000-00-00',

    isRedeemed: (bond) => bond.daysTillRepayment <= 0,

    prepareDisplayData: (bond, t, card) => {
        const percent = '%';
        const days = ' ' + t('Endings.days');
        const rub = ' ' + t('Endings.rub');
        const pieces = ' ' + t('Endings.pieces');
        const lots = ' ' + t('Endings.lots');
        // const own = ' ' + t('Endings.own');
        const isRedeemed = Functions.isRedeemed(bond);

        const round = (number, symbols = 2) => parseFloat(number).toFixed(symbols);
        const addPlus = (value) => (value > 0 ? '+' : '') + value;
        const brackets = (value) => ' (' + value + ')';

        const daysToMonth = (days, plus) => {
            if (days) {
                const month = days / 30;
                const roundMonth = round(month, 1);
                return brackets((plus ? addPlus(roundMonth) : roundMonth) + ' ' + t('Endings.months'));
            }
            else
                return '';
        };

        const addByValue = (field, fillValue, ifExists, otherField) => {
            const value = bond[field];
            let displayBondField = field;
            if (otherField)
                displayBondField = otherField;
            
            if (!Functions.isSet(value) || (!isNaN(parseFloat(value)) && !isFinite(parseFloat(value)))) {
                if (!ifExists)
                    displayBond[displayBondField] = '-';
            }
            else
                displayBond[displayBondField] = fillValue;
        };

        const addByField = (field, postfix, ifExists) => {
            let fillValue = bond[field];
            if (postfix)
                fillValue += postfix;
            addByValue(field, fillValue, ifExists);
        };

        let displayBond = {};
        addByField('name');
        addByValue('finalDate', Functions.formatDate(bond.finalDate));
        addByValue('daysTillRepayment', Functions.triad(bond.daysTillRepayment) + days + daysToMonth(bond.daysTillRepayment));
        addByField('purchasePercent', percent, true);
        addByValue('purchaseDate', Functions.formatDate(bond.purchaseDate), true);
        addByField('rate', percent);
        addByField('amount', pieces, true);
        addByField('bid', percent);
        addByField('offer', percent);
        addByValue('revenueByRepaymentPercent', round(bond.revenueByRepaymentPercent) + percent);
        addByField('currentPercent', percent);
        addByValue('baseDiffInDays', addPlus(bond.baseDiffInDays) + days + daysToMonth(bond.baseDiffInDays, true));
        addByValue('currentRevenuePercent', round(bond.currentRevenuePercent) + percent);
        addByValue('currentYearRevenuePercent', round(bond.currentYearRevenuePercent) + percent);
        addByField('daysTillCoupon', days);
        addByValue('currentRevenue', Functions.triad(round(bond.currentRevenue)) + rub + brackets(bond.calculatedDaysOwn + days));
        addByValue('totalBid', Functions.triad(bond.totalBid) + lots);
        addByValue('totalOffer', Functions.triad(bond.totalOffer) + lots);
        addByValue('bidOfferRatio', round(bond.bidOfferRatio));
        addByValue('priceChange', addPlus(bond.priceChange) + percent);
        addByValue('prevDayPriceChange', addPlus(bond.prevDayPriceChange) + percent);
        addByValue('tradeCount', Functions.triad(bond.tradeCount) + pieces);
        addByValue('totalTradeVolume', Functions.triad(bond.totalTradeVolume) + lots);
        addByValue('lastTradeVolume',Functions.triad(bond.lastTradeVolume) + lots);
        addByField('lastTradeTime');
        addByField('openPrice', percent);
        addByField('lowPrice', percent);
        addByField('highPrice', percent);
        addByField('spread', percent);
        addByField('lotValue', rub);
        addByField('averagePrice', percent);
        addByValue('prevDayAveragePriceChange', addPlus(bond.prevDayAveragePriceChange) + percent);
        addByField('code');
        addByValue('listing', t('Filters.listing.level' + bond.listing));
        const nkdPercent = round(bond.nkdPercent, 1) + percent;
        addByValue('nkdPercent', nkdPercent);
        addByValue('nkd', bond.nkd + rub + brackets(nkdPercent));
        addByValue('nkdForAmount', addPlus(Functions.triad(round(bond.nkdForAmount))) + rub + brackets(addPlus(round(bond.nkdPercent, 1)) + percent));
        addByValue('buyNkd', addPlus(Functions.triad(round(-bond.buyNkd))) + rub + brackets(addPlus(round(-bond.buyNkdPercent, 1)) + percent));
        addByValue('buyNkdWithDays', addPlus(Functions.triad(round(-bond.buyNkd))) + rub + brackets(addPlus(-bond.buyNkdInDays) + days));
        addByValue('buyNkdPercent', round(bond.buyNkdPercent) + percent);
        addByValue('calculatedDaysOwnDiff', addPlus(bond.calculatedDaysOwnDiff) + days + daysToMonth(bond.calculatedDaysOwnDiff, true));
        addByValue('rateToBaseDiffInDays', round(bond.rateToBaseDiffInDays));
        addByValue('currentSellPeriod', bond.currentSellPeriod + days + daysToMonth(bond.currentSellPeriod));
        addByValue('currentWaitPeriod', bond.currentWaitPeriod + days + daysToMonth(bond.currentWaitPeriod));
        addByValue('nkdPeriod', bond.sellPeriod + days + ' / ' + bond.waitPeriod + days);
        addByField('sellPeriod', days);
        addByField('waitPeriod', days);
        if (Functions.isSet(bond.lowPrice) && Functions.isSet(bond.highPrice) && Functions.isSet(bond.openPrice))
            addByValue('lowHighPrice', bond.lowPrice + percent + " - " + bond.highPrice + percent + brackets(bond.openPrice + percent));
        else
            addByValue('lowHighPrice', '-');
        addByValue('realRate', round(bond.realRate) + percent);
        addByValue('remainRepaymentRate', round(bond.remainRepaymentRate) + percent);
        if (card) {
            addByField('fullName');
            addByField('engName');
            addByField('isin');
            addByValue('purchaseCostForAmount', Functions.triad(round(bond.purchaseCostForAmount)) + rub);
            addByValue('currentCostForAmount', Functions.triad(round(bond.currentCostForAmount)) + rub);
            addByValue('costDiff', addPlus(Functions.triad(round(bond.costDiff))) + rub + brackets(addPlus(bond.purchaseDiffInDays) + days));
            addByValue('daysOwn', bond.daysOwn + days + daysToMonth(bond.daysOwn));
            addByValue('daysTillRedemption', Functions.triad(bond.daysTillRedemption) + days + daysToMonth(bond.daysTillRedemption));
            addByValue('repaymentDate', Functions.formatDate(bond.repaymentDate));
            addByValue('buyBackDate', Functions.formatDate(bond.buyBackDate));
            addByField('buyBackPrice', percent);
            addByField('couponPeriod', days);
            addByValue('couponDate', Functions.formatDate(bond.couponDate));
            addByValue('couponPayment', Functions.triad(round(bond.couponPayment)) + rub);
            addByValue('couponPaymentBefore', Functions.triad(round(bond.couponPaymentBefore)) + rub);
            addByValue('couponPaymentAfter', Functions.triad(round(bond.couponPaymentAfter)) + rub);
            addByValue('guaranteedRevenueByRepaymentPercent', round(bond.guaranteedRevenueByRepaymentPercent) + percent);
            addByValue('guaranteedRevenueByRepayment', Functions.triad(round(bond.guaranteedRevenueByRepayment)) + rub + brackets((bond.guaranteedRevenueByRepaymentInDays) + days));
            addByValue('guaranteedRevenueDiff', Functions.triad(round(bond.guaranteedRevenueDiff)) + rub + brackets(bond.guaranteedRevenueDiffInDays + days));
            addByValue('sellNowValue', addPlus(Functions.triad(round(-bond.guaranteedRevenueDiff))) + rub + brackets(addPlus(-bond.guaranteedRevenueDiffInDays) + days));
            addByValue('guaranteedRevenueDiffInDays', bond.guaranteedRevenueDiffInDays + days + daysToMonth(bond.guaranteedRevenueDiffInDays));
            addByValue('dayRevenueForAmount', Functions.triad(round(bond.dayRevenueForAmount)) + rub);
            addByValue('dayRevenueForAmount', Functions.triad(round(bond.dayRevenueForAmount * 30)) + rub, false, 'monthRevenueForAmount');
            addByValue('dayRevenueForAmount', Functions.triad(round(bond.dayRevenueForAmount * 365)) + rub, false, 'yearRevenueForAmount');
            addByValue('feeForPurchase', round(-bond.feeForPurchase) + rub + brackets(addPlus(bond.feeForPurchaseInDays) + days));
            addByValue('feeForSale', addPlus(round(-bond.feeForSale)) + rub +  brackets(addPlus(bond.feeForSaleInDays) + days));
            addByValue('extraRevenuePercent', round(bond.extraRevenuePercent) + percent);
            addByValue('nob', Functions.triad(round(bond.nob)) + rub + brackets(bond.nobInDays + days));
            addByValue('tradeRevenue', addPlus(Functions.triad(round(bond.tradeRevenue))) + rub + brackets(addPlus(bond.tradeRevenueInDays) + days));
            addByValue('repaymentNob', Functions.triad(round(bond.repaymentNob)) + rub + brackets(bond.repaymentNobInDays + days));
            addByValue('guaranteedTradeRevenue', addPlus(Functions.triad(round(bond.guaranteedTradeRevenue))) + rub + brackets(addPlus(bond.guaranteedTradeRevenueInDays) + days));
            addByValue('purchaseCostWithNkd', Functions.triad(round(bond.purchaseCostWithNkd)) + rub);
            addByValue('calculatedDaysOwn', bond.calculatedDaysOwn + days + daysToMonth(bond.calculatedDaysOwn));
            addByValue('purchaseDiffInDays', addPlus(bond.purchaseDiffInDays) + days + daysToMonth(bond.purchaseDiffInDays, true));
            addByValue('nkdDelta', addPlus(Functions.triad(round(bond.nkdDelta))) + rub + brackets(addPlus(bond.nkdDeltaInDays) + days));
            addByValue('allCoupons', addPlus(Functions.triad(round(bond.allCoupons))) + rub + brackets(addPlus(bond.allCouponsInDays) + days));
            addByValue('allCouponsBefore', addPlus(Functions.triad(round(bond.allCouponsBefore))) + rub + brackets(addPlus(bond.allCouponsBeforeInDays) + days));
            addByValue('allCouponsAfter', addPlus(Functions.triad(round(bond.allCouponsAfter))) + rub + brackets(addPlus(bond.allCouponsAfterInDays) + days));
            addByValue('coupons', addPlus(Functions.triad(round(bond.coupons))) + rub + brackets(addPlus(bond.couponsInDays) + days));
            addByValue('couponsBefore', addPlus(Functions.triad(round(bond.couponsBefore))) + rub + brackets(addPlus(bond.couponsBeforeInDays) + days));
            addByValue('couponsAfter', addPlus(Functions.triad(round(bond.couponsAfter))) + rub + brackets(addPlus(bond.couponsAfterInDays) + days));
            addByValue('baseCostDiff', addPlus(Functions.triad(round(bond.baseCostDiff))) + rub + brackets(addPlus(bond.baseCostDiffInDays) + days));
            addByField('couponPaymentCount');
            addByField('couponPaymentCountBefore');
            addByField('couponPaymentCountAfter');
            addByField('zeroOnePercentChangeInDays', days);
            addByField('onePercentChangeInDays', days);
            addByValue('sector', t('Bond.' + bond.sector));
        }

        displayBond.extra = {};
        displayBond.extra.bondTitle = Functions.getLocalBondName(bond);
        displayBond.extra.isRedeemed = isRedeemed;
        displayBond.extra.putOffer = bond.putOffer;
        displayBond.extra.putClass = '';
        displayBond.extra.redeemedClass = '';
        displayBond.extra.putRedeemedClass = '';
        if (!isRedeemed) {
            if (bond.putOffer) {
                displayBond.extra.revenuePercentTitle = t('Table.offerRevenue');
                displayBond.extra.dateTitle = t('Table.offerDate');
                displayBond.extra.daysTitle = t('Table.offerDays');
                displayBond.extra.putClass = 'putOffer';
                displayBond.extra.bondTitle += ' / ' + t('Table.putOffer');
            }
        }
        else {
            displayBond.extra.redeemedClass = 'redeemed';
            displayBond.extra.bondTitle += ' / ' + t('Table.redeemed');
        }
        displayBond.extra.putRedeemedClass = displayBond.extra.putClass + ' ' + displayBond.extra.redeemedClass;

        return displayBond;
    },

    isCrawlingMode: () => navigator.userAgent === "ReactSnap",

    saveCookie: (name, value) => {
        cookie.save(name, value, {
            path: '/',
            maxAge: 31536000 // 1 year
         });
    },

    readCookie: (name) => cookie.load(name),

    fillDemoData: async (storage, dispatch, priceType, realRatePeriod) => {
        const apply = (bonds) => dispatch({ type: 'setBonds', bonds: bonds });
        const url = "/" + storage + ".json";
        return fetch(url).then(response => response.text()).then(text => {
            if (text) {
                window.localStorage.setItem(storage, text);
                return Functions.fillBonds(storage, dispatch).then(allBonds => apply(Functions.calculateRelativeTo(allBonds, priceType, dispatch, realRatePeriod)));
            }
        });
    },

    getBondFields: () => [
        'rate', 'currentYearRevenuePercent', 'currentRevenuePercent', 'purchasePercent', 'currentPercent', 'amount',
        'purchaseCostForAmount', 'currentCostForAmount', 'costDiff', 'purchaseDiffInDays', 'purchaseDate', 'daysOwn',
        'yearsTillRepayment', 'buyBackDate', 'daysTillRedemption', 'repaymentDate', 'buyBackPrice', 'couponPeriod',
        'couponDate', 'daysTillCoupon', 'couponPayment', 'couponEarnings', 'currentRevenue', 'guaranteedRevenueDiff',
        'dayRevenueForAmount', 'monthRevenueForAmount', 'yearRevenueForAmount', 'name',
        'fullName', 'engName', 'code', 'isin', 'baseDiffInDays', 'finalDate', 'bid', 'offer', 'totalBid', 'totalOffer',
        'bidOfferRatio', 'priceChange', 'prevDayPriceChange', 'tradeCount', 'totalTradeVolume', 'lastTradeVolume', 
        'lastTradeTime', 'openPrice', 'lowPrice', 'highPrice', 'spread', 'lotValue', 'averagePrice', 'prevDayAveragePriceChange',
        'listing', 'feeForPurchase', 'feeForSale', 'nkd', 'nkdForAmount', 'buyNkd', 'buyNkdWithDays', 'tradeRevenue', 'guaranteedTradeRevenue',
        'purchaseCostWithNkd', 'nkdPercent', 'calculatedDaysOwn', 'calculatedDaysOwnDiff', 'rateToBaseDiffInDays', 'nkdDelta',
        'allCoupons', 'coupons', 'nob', 'repaymentNob', 'baseCostDiff', 'couponPaymentCount', 'currentSellPeriod', 'currentWaitPeriod',
        'nkdPeriod', 'buyNkdPercent', 'lowHighPrice', 'onePercentChangeInDays', 'zeroOnePercentChangeInDays', 'couponPaymentCountBefore',
        'couponPaymentCountAfter', 'couponPaymentBefore', 'couponPaymentAfter', 'couponsBefore', 'couponsAfter', 'allCouponsBefore',
        'allCouponsAfter', 'realRate', 'revenueByRepaymentPercent', 'daysTillRepayment', 'guaranteedRevenueByRepayment',
        'guaranteedRevenueByRepaymentPercent', 'guaranteedRevenueDiffInDays', 'sector', 'remainRepaymentRate'
    ],

    getBondTitle: ({ put, redeemed, table, t }) => { 
        let result = {};
        Functions.getBondFields().forEach(value => result[value] = t('Bond.' + value));
        result.revenueByRepaymentPercent = t('Bond.revenueByRepaymentPercent.part1') + (table ? t('Bond.revenueByRepaymentPercent.part2') : (put ? t('Bond.revenueByRepaymentPercent.part3') : t('Bond.revenueByRepaymentPercent.part4')));
        result.daysTillRepayment = t('Bond.daysTillRepayment.part1') + (table ? t('Bond.daysTillRepayment.part2') : (put && !redeemed ? t('Bond.daysTillRepayment.part3') : t('Bond.daysTillRepayment.part4')));
        result.guaranteedRevenueByRepayment = t('Bond.guaranteedRevenueByRepayment.part1') + (put ? t('Bond.guaranteedRevenueByRepayment.part2') : t('Bond.guaranteedRevenueByRepayment.part3'));
        result.guaranteedRevenueByRepaymentPercent = t('Bond.guaranteedRevenueByRepayment.part1') + (put ? t('Bond.guaranteedRevenueByRepayment.part2') : t('Bond.guaranteedRevenueByRepayment.part3'));
        result.guaranteedRevenueDiffInDays = t('Bond.guaranteedRevenueDiffInDays.part1') + (put ? t('Bond.guaranteedRevenueDiffInDays.part2') : t('Bond.guaranteedRevenueDiffInDays.part3'));
        return result;
    },

    getTableColumns: (t) => {
        let columns = {
            bondCard: {
                name: t('Columns.bond')
            },
            newBondCard: {
                name: t('Columns.bond')
            },
            actions: {
                name: t('Columns.actions')
            },
            rate: {
                sorting: ['sortByRateDesc', 'sortByRateAsc']
            },
            amount: { 
                sorting: ['sortByAmountDesc', 'sortByAmountAsc']
            },
            purchasePercent: { 
                sorting: ['sortByPurchasePercentDesc', 'sortByPurchasePercentAsc']
            },
            currentPercent: {
                sorting: ['sortByPriceDesc', 'sortByPriceAsc']
            },
            revenueByRepaymentPercent: {
                sorting: ['sortByRevenueDesc', 'sortByRevenueAsc']
            },
            currentRevenuePercent: {
                sorting: ['sortByCurrentRevenueDesc', 'sortByCurrentRevenueAsc']
            },
            currentYearRevenuePercent: {
                sorting: ['sortByCurrentYearRevenueDesc', 'sortByCurrentYearRevenueAsc']
            },
            daysTillRepayment: {
                sorting: ['sortByDaysTillRepaymentDesc', 'sortByDaysTillRepaymentAsc']
            },
            daysTillCoupon: {
                sorting: ['sortDaysTillCouponDesc', 'sortDaysTillCouponAsc']
            },
            purchaseDate: {},
            baseDiffInDays: {
                sorting: ['sortByBaseDiffInDaysDesc', 'sortByBaseDiffInDaysAsc'],
            },
            finalDate: {},
            bid: {
                sorting: ['sortByBidDesc', 'sortByBidAsc']
            },
            offer: {
                sorting: ['sortByOfferDesc', 'sortByOfferAsc']
            },
            totalBid: {
                sorting: ['sortByTotalBidDesc', 'sortByTotalBidAsc']
            },
            totalOffer: {
                sorting: ['sortByTotalOfferDesc', 'sortByTotalOfferAsc']
            },
            bidOfferRatio: {
                sorting: ['sortByBidOfferRatioDesc', 'sortByBidOfferRatioAsc']
            },
            priceChange: {
                sorting: ['sortByPriceChangeDesc', 'sortByPriceChangeAsc']
            },
            prevDayPriceChange: {
                sorting: ['sortByPrevDayPriceChangeDesc', 'sortByPrevDayPriceChangeAsc']
            },
            tradeCount: {
                sorting: ['sortByTradeCountDesc', 'sortByTradeCountAsc']
            },
            totalTradeVolume: {
                sorting: ['sortByTotalTradeVolumeDesc', 'sortByTotalTradeVolumeAsc']
            },
            lastTradeVolume: {
                sorting: ['sortByLastTradeVolumeDesc', 'sortByLastTradeVolumeAsc']
            },
            lastTradeTime: {},
            openPrice: {
                sorting: ['sortByOpenPriceDesc', 'sortByOpenPriceAsc']
            },
            lowPrice: {
                sorting: ['sortByLowPriceDesc', 'sortByLowPriceAsc']
            },
            highPrice: {
                sorting: ['sortByHighPriceDesc', 'sortByHighPriceAsc']
            },
            spread: {
                sorting: ['sortBySpreadDesc', 'sortBySpreadAsc']
            },
            lotValue: {
                sorting: ['sortByLotValueDesc', 'sortByLotValueAsc']
            },
            averagePrice: {
                sorting: ['sortByAveragePriceDesc', 'sortByAveragePriceAsc']
            },
            prevDayAveragePriceChange: {
                sorting: ['sortByPrevDayAveragePriceChangeDesc', 'sortByPrevDayAveragePriceChangeAsc']
            },
            code: {},
            listing: {},
            nkdPercent: {
                sorting: ['sortByNkdPercentDesc', 'sortByNkdPercentAsc']
            },
            calculatedDaysOwnDiff: {
                sorting: ['sortByCalculatedDaysOwnDiffDesc', 'sortByCalculatedDaysOwnDiffAsc']
            },
            rateToBaseDiffInDays: {
                sorting: ['sortByRateToBaseDiffInDaysDesc', 'sortByRateToBaseDiffInDaysAsc']
            },
            nkdPeriod: {},
            currentSellPeriod: {
                sorting: ['sortByCurrentSellPeriodDesc', 'sortByCurrentSellPeriodAsc']
            },
            currentWaitPeriod: {
                sorting: ['sortByCurrentWaitPeriodDesc', 'sortByCurrentWaitPeriodAsc']
            },
            buyNkdPercent: {
                sorting: ['sortByBuyNkdPercentDesc', 'sortByBuyNkdPercentAsc']
            },
            lowHighPrice: {},
            realRate: {
                sorting: ['sortByRealRateDesc', 'sortByRealRateAsc']
            },
            remainRepaymentRate: {
                sorting: ['sortByRemainRepaymentRateDesc', 'sortByRemainRepaymentRateAsc']
            }
        };

        const bondTitle = Functions.getBondTitle({ table : true, t });
        for (const name in columns) {
            if (columns[name].name)
                continue;
            columns[name].name = bondTitle[name];
        }

        return columns;
    },

    isSet: (value) => value !== null && value !== undefined,

    useTimeout: (callback, delay) => {
        useEffect(() => {
            let timer = window.setTimeout(() => callback(), delay);
            return () => window.clearTimeout(timer);
        }, [callback, delay]);
    },

    colorPercentChange: (value, title) => {
        const plus = /\+/;
        const minus = /-/;
        let className = 'percentChange';
        if (plus.test(value))
            className += ' percentUp';
        if (minus.test(value))
            className += ' percentDown';
        if (!Functions.isSet(title))
            title = '';
        else
            className += ' cursorHelp';
        return <Btooltip title={title} enterDelay={Functions.tooltipDelay()}><span className={className}>{value}</span></Btooltip>;
    },

    boolForLs: (value) => value ? 1 : '',

    scrollTo: (id, noEffects) => {
        const element = document.getElementById(id);
        if (!element)
            return;
            
        if (noEffects)
            element.scrollIntoView();
        else
            element.scrollIntoView({ behavior: "smooth" });
    },

    actionButtonNames: (name, t)  => ({
        readyName: t('Buttons.' + name + '.start'),
        loadingName: t('Buttons.' + name + '.loading')
    }),

    calculateRelativeTo: (bonds, priceType, dispatch, realRatePeriod) => {
        const calculatedBonds = bonds.map(bond => Functions.calculate(bond, priceType, realRatePeriod));
        dispatch({ type: 'saveCalculatedBonds', calculatedBonds: calculatedBonds});
        return calculatedBonds;
    },

    // writeFilterData: (data) => Functions.setToLs(data, 'filters'),

    // readFilterData: () => {
    //     const filters = Functions.getFromLs('filters');
    //     window.localStorage.removeItem('filters');
    //     return filters;
    // },

    getYandexID: () => {
        if (Functions.isRus())
            return 57188851;
        else {
            if (Functions.isEng())
                return 72491974;
        }
    },

    setYandexGoal: (name) => {
        const id = Functions.getYandexID();
        window.ym(id, 'reachGoal', name);
    },

    getFees: () => Functions.getFromLs('fees'),

    setFees: (fees) => {
        Functions.setToLs(fees, 'fees');
    },

    newAndFindBondsColumns: (extraColumns, actions) => {
        let result;
        if (extraColumns) {
            result = [
               'newBondCard',
               'rate',
               'realRate',
               'rateToBaseDiffInDays',
               'revenueByRepaymentPercent',
               'currentPercent',
               'bid',
               'lowHighPrice',
               'averagePrice',
               'offer',
               'spread',
               'baseDiffInDays',
               'nkdPercent',
               'nkdPeriod',
               'tradeCount',
               'totalTradeVolume',
               'lastTradeVolume',
               'lastTradeTime',
               'totalBid',
               'totalOffer',
               'bidOfferRatio',
               'lotValue',
               'listing',
               'daysTillCoupon',
               'daysTillRepayment',
               'finalDate',
               'code'
            ];
         }
         else {
            result = [
               'newBondCard',
               'rate',
               'realRate',
               'rateToBaseDiffInDays',
               'revenueByRepaymentPercent',
               'currentPercent',
               'bid',
               'lowHighPrice',
               'averagePrice',
               'baseDiffInDays',
               'nkdPercent',
               'nkdPeriod',
               'tradeCount',
               'totalTradeVolume',
               'bidOfferRatio',
               'lastTradeTime',
               'lotValue',
               'daysTillRepayment',
               'finalDate',
               'code'
            ];
         }
        if (actions === true)
            result.push('actions');
        return result;
    },

    getRowValues: (t, bond) => {
        const bondTitle = Functions.getBondTitle({ table: true, t });
        const displayBond = Functions.prepareDisplayData(bond, t);

        const composeCurrentPercent = (key) => (
            <td key={key}>
                <div>{displayBond.currentPercent}</div>
                <div>
                    {Functions.colorPercentChange(displayBond.priceChange, bondTitle.priceChange)}
                    <span className="percentChange"> / </span>
                    {Functions.colorPercentChange(displayBond.prevDayPriceChange, bondTitle.prevDayPriceChange)}
                </div>
            </td>
        );
        const composeAveragePrice = (key) => (
            <td key={key}>
                <div>{displayBond.averagePrice}</div>
                <div>
                    {Functions.colorPercentChange(displayBond.prevDayAveragePriceChange, bondTitle.prevDayAveragePriceChange)}
                </div>
            </td>
        );
        const composeNkdPeriod = (key) => (
            <td key={key}>
                <div>
                    <Btooltip title={bondTitle.currentSellPeriod} enterDelay={Functions.tooltipDelay()}><span>{displayBond.sellPeriod}</span></Btooltip>
                    {' / '}
                    <Btooltip title={bondTitle.currentWaitPeriod} enterDelay={Functions.tooltipDelay()}><span>{displayBond.waitPeriod}</span></Btooltip>
                </div>
            </td>
        );

        let key = 1;
        return {
            bondCard: <td key={key++}><BondCardLink bond={bond} myBond={true} /></td>,
            newBondCard: <td key={key++}><BondCardLink bond={bond} /></td>,
            rate: <td key={key++} className={displayBond.extra.redeemedClass}>{displayBond.rate}</td>,
            amount: <td key={key++} className={displayBond.extra.redeemedClass}>{displayBond.amount}</td>,
            purchasePercent: <td key={key++} className={displayBond.extra.redeemedClass}>{displayBond.purchasePercent}</td>,
            currentPercent: composeCurrentPercent(key++),
            bid: <td key={key++}>{displayBond.bid}</td>,
            offer: <td key={key++}>{displayBond.offer}</td>,
            spread: <td key={key++}>{displayBond.spread}</td>,
            lowHighPrice: <td key={key++}>{displayBond.lowHighPrice}</td>,
            averagePrice: composeAveragePrice(key++),
            calculatedDaysOwnDiff: <td key={key++}>{displayBond.calculatedDaysOwnDiff}</td>,
            revenueByRepaymentPercent: <td key={key++} className={displayBond.extra.putClass}>{Functions.addTooltipIfExists(displayBond.extra.revenuePercentTitle, displayBond.revenueByRepaymentPercent)}</td>,
            currentYearRevenuePercent: <td key={key++}>{displayBond.currentYearRevenuePercent}</td>,
            currentRevenuePercent: <td key={key++}>{displayBond.currentRevenuePercent}</td>,
            currentSellPeriod: <td key={key++}>{displayBond.currentSellPeriod}</td>,
            currentWaitPeriod: <td key={key++}>{displayBond.currentWaitPeriod}</td>,
            nkdPeriod: composeNkdPeriod(key++),
            buyNkdPercent: <td key={key++}>{displayBond.buyNkdPercent}</td>,
            nkdPercent: <td key={key++}>{displayBond.nkdPercent}</td>,
            tradeCount: <td key={key++}>{displayBond.tradeCount}</td>,
            totalTradeVolume: <td key={key++}>{displayBond.totalTradeVolume}</td>,
            lastTradeVolume: <td key={key++}>{displayBond.lastTradeVolume}</td>,
            lastTradeTime: <td key={key++}>{displayBond.lastTradeTime}</td>,
            totalBid: <td key={key++}>{displayBond.totalBid}</td>,
            totalOffer: <td key={key++}>{displayBond.totalOffer}</td>,
            bidOfferRatio: <td key={key++}>{displayBond.bidOfferRatio}</td>,
            lotValue: <td key={key++}>{displayBond.lotValue}</td>,
            listing: <td key={key++}>{displayBond.listing}</td>,
            daysTillCoupon: <td key={key++} className={displayBond.extra.redeemedClass}>{displayBond.daysTillCoupon}</td>,
            daysTillRepayment: <td key={key++} className={displayBond.extra.putRedeemedClass}>{Functions.addTooltipIfExists(displayBond.extra.daysTitle, displayBond.daysTillRepayment)}</td>,
            purchaseDate: <td key={key++} className={displayBond.extra.redeemedClass}>{displayBond.purchaseDate}</td>,
            code: <td key={key++}>{displayBond.code}</td>,
            rateToBaseDiffInDays :<td key={key++}>{displayBond.rateToBaseDiffInDays}</td>,
            realRate: <td key={key++}>{displayBond.realRate}</td>,
            baseDiffInDays: <td key={key++}>{displayBond.baseDiffInDays}</td>,
            finalDate: <td key={key++} className={displayBond.extra.putRedeemedClass}>{Functions.addTooltipIfExists(displayBond.extra.dateTitle, displayBond.finalDate)}</td>,
            remainRepaymentRate: <td key={key++}>{displayBond.remainRepaymentRate}</td>
        };
    },

    mapRows: (columns, data) => {
        return columns.map(value => data[value]);
    },

    mapColumns: (columns, data) => {
        return columns.map(value => ({ ...data[value], field: value }));
    },

    getLocalBondName: (bond) => {
        if (Functions.isRus())
            return bond.name;
        else
            return bond.engName;
    },

    getBondTooltips: (t) => {
        const result = {};
        let pattern = /^BondTooltip/;
        Functions.getBondFields().forEach(field => {
            const value = t('BondTooltip.' + field);
            if (!pattern.test(value))
            result[field] = value;
        }); 
        return result;
    },

    tooltipDelay: () => 250,

    addTooltipIfExists: (title, value) => {
        if (Functions.isSet(title))
            return <Btooltip title={title} enterDelay={Functions.tooltipDelay()}><span>{value}</span></Btooltip>;
        else
            return value;
    },

    triad: (value) => value ? value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '): value,

    formatDate: function(dateString) {
        if (Intl && Intl.DateTimeFormat && Functions.isDateValid(dateString)) {
            var formatter = new Intl.DateTimeFormat(Functions.getCurrentLanguage(), {
                year: "numeric",
                month: "long",
                day: "numeric"
            });
            const date = new Date(dateString);
            return formatter.format(date);
        }
        else
            return dateString;
    }
};