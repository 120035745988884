import React, { useState, useMemo, memo } from 'react';
import { Button, Form, FormGroup, Input, FormFeedback, FormText, Col, Container } from 'reactstrap';
import { Header, Dialog, BondTable, DeleteIcon, ImportExport, BondCodeLink, LsWrap, TableNotice, DemoNotice, LinksToCrawl, ExtraColumns, Filters } from './modules';
import { Functions } from './functions.js';
import { connect, Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { bonds, currentSorting, allBonds, calculatedBonds, ndflFreeBonds, tableLoaded, priceType, realRatePeriod } from './store/reducers';
import { useTranslation, Trans } from 'react-i18next';
import { useXstateForButton } from 'xstate-button';

const reducer = combineReducers({
   bonds,
   currentSorting,
   allBonds,
   calculatedBonds,
   ndflFreeBonds,
   tableLoaded,
   priceType,
   realRatePeriod
});
const store = createStore(reducer);

export const NewBonds = () => {
   const { t } = useTranslation();
   const header = t('NewBonds.header');

   return (
      <Provider store={store}>
         <Header>{header}</Header>
         <Text />
         <LsWrap>
            <NewBondsFilters header={header} />
            <NewBondsTable />
            <DemoNotice storage="newBonds" />
            <TableNotice />
         </LsWrap>
         <LinksToCrawl links={['aboutCode', 'feedbackTest']} />
      </Provider>
   );
};

const Text = () => {
   const { t } = useTranslation();
   return (
      <Container>
         <p>
            {t('NewBonds.text.part1')}
         </p>
         <p className="mb-5">
            {t('NewBonds.text.part2')}
         </p>
      </Container>
   );
};

let NewBondsFilters = ({ header, dispatch }) => {
   const fillBonds = () => Functions.fillBonds("newBonds", dispatch);
   return (
      <Filters read={fillBonds} minified={true}>
         <span className="m-1 inlineBlock">
            <AddBond />
         </span>
         <span className="m-1 inlineBlock">
            <ImportExport storage="newBonds" downloadName={header} />
         </span>
      </Filters>
   );
};
NewBondsFilters = connect(state => state)(NewBondsFilters);

let NewBondsTable = ({ bonds, currentSorting, tableLoaded, dispatch }) => {
   const { t } = useTranslation();
   const [extraColumns, setExtraColumns] = useState(() => !!window.localStorage.extraColumns);
   const headers = useMemo(() => Functions.mapColumns(Functions.newAndFindBondsColumns(extraColumns, true), Functions.getTableColumns(t)), [extraColumns, t]);

   return (
      <div>
         <ExtraColumns extraColumns={extraColumns} setExtraColumns={setExtraColumns} />
         <BondTable {...{ bonds, headers, currentSorting, dispatch, TableRow, tableLoaded, deleteProp: 'code', extraColumns }} />
      </div>
   );
};
NewBondsTable = connect(state => state)(NewBondsTable);

const TableRow = memo(({ bond, deleteBond, extraColumns }) => {
   const { t } = useTranslation();
   if (!bond)
      return null;

   const handleDelete = (code) => {
      if (Functions.confirmDelete(t) && code) {
         const bonds = Functions.getNewBonds().filter(bond => bond.code !== code);
         Functions.updateNewBonds(bonds);
         deleteBond(code);
      }
   }

   return (
      <tr>
         {Functions.mapRows(Functions.newAndFindBondsColumns(extraColumns, true), Functions.getRowValues(t, bond))}
         <td key={100} className="bondsTableActions">
            <span onClick={() => handleDelete(bond.code)}>
               <DeleteIcon />
            </span>
         </td>
      </tr>
   );
});

 let AddBond = ({ currentSorting, dispatch, priceType, realRatePeriod }) => {
   const { t } = useTranslation();
   const [open, setOpen] = useState(false);
   const [invalid, setInvalid] = useState(false);
   const [errorMessage, setErrorMessage] = useState();
   const toggleDialog = () => setOpen(!open);
   const onClosed = () => setInvalid(false);
   const [codeRef, codeInput] = Functions.useRef();
   const dialogProps = { header: t('BondDialog.header.add'), open, toggleDialog, onClosed };

   const [ start, buttonName, buttonDisabled ] = useXstateForButton(async (context, event) => {
      const { code } = event;
      const newBond = { code: code.toUpperCase() };
      return Functions.updateBond(newBond).then(bond => {
         const dispatchBond = (type, bond) => dispatch({ type: type, bond: bond });
         newBond.repaymentDate = bond.repaymentDate;
         dispatchBond('addToAllBonds', bond);
         let bonds = Functions.getNewBonds();
         bonds.push(newBond);
         Functions.updateNewBonds(bonds);
         bond = Functions.calculate(bond, priceType, realRatePeriod);
         dispatchBond('addToCalculatedBonds', bond);
         dispatchBond('addBond', bond);
         dispatch({
            type: 'applySorting',
            sorting: currentSorting
         });
         setInvalid(false);
         toggleDialog();
         Functions.setYandexGoal('NewBondAdded');
      }).catch(error => {
         setInvalid(true);
         setErrorMessage(t('BondDialog.errors.wrongCode'));
      });
   }, Functions.actionButtonNames('add', t));

    const handleAddition = (event) => {
        event.preventDefault();
        if (codeInput.value.length > 0) {
            const code = codeInput.value;
            if (!isDuplicate(code))
                start({ code: code });
            else {
                setInvalid(true);
                setErrorMessage(t('BondDialog.errors.alreadyExists'));
            }
        }
        else {
            setInvalid(true);
            setErrorMessage(t('BondDialog.errors.cannotBeEmpty'));
        }
    };

    const isDuplicate = (code) => {
        return Functions.getNewBonds().some(bond => bond.code === code);
    };

   let tabIndex = 1;

   return (
    <span>
      <Button color="info" data-test-id="addBond" outline onClick={toggleDialog}>{t('Buttons.addBond')}</Button>
      <Dialog { ...dialogProps }>
         <Form name="editBond" onSubmit={handleAddition}>
            <FormGroup row>
               <Col>
                  <Input invalid={invalid} type="text" name="code" innerRef={codeRef} autoComplete="off" placeholder={t('BondDialog.labels.code')} tabIndex={tabIndex++} />
                  <FormFeedback valid={!invalid}>{errorMessage}</FormFeedback>
                  <FormText>{t('BondDialog.hints.inputCode.newBonds')} (<BondCodeLink t={t} />)</FormText>
               </Col>
               <Col sm="auto" className="alignRight"> 
                  <Button name="save" disabled={buttonDisabled} color="info" tabIndex={tabIndex++}>{buttonName}</Button>
               </Col>
            </FormGroup>
         </Form>
      </Dialog>
    </span>
  );
};
AddBond = connect(state => state)(AddBond);